import React, { useState } from 'react';

function App() {



  return (
    <>
      
      <h1 className="center">ScriptCats</h1>
	    <div className="center clear-panel">
	      <div id="main" style={{"display":"block"}}>

          <div className="panel">
            <div className="info">
            <h4>ScriptCats are a set of collections ethscribed on the Ethereum blockchain.</h4>
            </div>       
            <div className="center">
              <img style={{"max-height":"264px","margin":"15px"}} src="ScriptCats-600x264.png" />
            </div>
            <h4>
            ScriptCat OG
            </h4>  
            <p>
              First collection of 333 ScriptCats which have been ethscribed and sold out quickly.
            </p>
            <p>
              Buy on Ethscription market places like:
            </p>
            <p><a href='https://ethscriptions.com/collections/scriptcats-og' target='_blank'>Ethscriptions.com</a></p>
            <p><a href='https://ordex.ai/collection/eth/scriptcats' target='_blank'>ORDEX.ai</a></p>

            <div style={{"height":"50px"}}></div>

            <h4>
            Genesis ScriptCats
            </h4>  
            <p>
              96 rare black & white ScriptCats similar to their historical honors.
            </p>
            <p>
              Minting out fast !!!
            </p>
            <p>Available on Ethscription market places soon.</p>

            <div style={{"height":"50px"}}></div>

            <h4>
            ScriptCat Club
            </h4>  
            <p>
              Free mint of 25011 ScriptCats for all ScriptCat OG holders. 
            </p>
            <p>
              Minting just started
            </p>
            <p><a href='https://club.scriptcats.xyz' target='_blank'>Free Mint now</a></p>
          </div>
                                    
	    </div>

      
		  <div style={{"height":"100px"}}></div>

	    <div id="content" style={{"display":"block"}}>
		    <div className="panel">
		    	<p>ScriptCats ecosystem</p>
			    <h2>What is ScriptCat Club?</h2>
			    <p>ScriptCat Club is planned to be a collection of 25011 ScriptCats ethscribed into the Ethereum blockchain to honor the historical achievment of Mooncats.
          </p>
          <p> They are free to mint (or better to ethscribe) for holders of ScriptCats OG collection (333 NFTs) in order to make them more accessable and grow the community and spread the stroy of Mooncats.
          </p>
			  </div>
	    </div>


		  <div style={{"height":"100px"}}></div>

	    <div id="content" style={{"display":"block"}}>
		    <div className="panel">
		    	<p>ScriptCats are an honor to Mooncats</p>
			    <h2>What are Mooncats?</h2>
			    <p>Mooncats are procedurally generated pixel cats which are deterministically generated based on blockchain data. Initially minted in 2017, Mooncats are one of the oldest NFT projects !</p>
			    <h3>Examples</h3>
            <div className="center">
            <img style={{"max-height":"150px","margin":"15px"}} src="example1.png" />
            <img style={{"max-height":"150px","margin":"15px"}} src="example2.png" />
            <img style={{"max-height":"150px","margin":"15px"}} src="example3.png" />
            </div>
			  </div>
	    </div>

	    <div id="info" style={{"display":"block"}}>
        <div className="panel">
            <p>
          Minting ScriptCats through this page requires <a href="https://metamask.io/" target="_blank">MetaMask</a>. Please ensure you have it installed & running and that your account is unlocked.
            </p>
        </div>
	    </div>
	</div>



  



    </>
  );
}

export default App;
